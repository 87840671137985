import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "./modules/shared/services/utils.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'jd-panel';
  token?:string | null;

  constructor(
    private _title:Title,
    private _translate: TranslateService,
    private _util: UtilsService,
    private snack: MatSnackBar
  ) {
    this._title.setTitle("Tu Panel JD")
    try {
      const language = navigator.language
      if(language.includes("en") || language.includes("En")){
        this._translate.use("en")
      }else{
        this._translate.use("es")
      }
    }catch (e: any){
      console.log(e)
      this._translate.use('es')
    }

    this._translate.setDefaultLang('es')

    this._util.getObservableSnackError().subscribe(data=>{
      this.snack.open(data,"X",{
        panelClass: "error-snack",
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      })
    })

    this._util.getObservableSnackSuccess().subscribe(data=>{
      this.snack.open(data, "X",{
        panelClass: "success-snack",
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      })
    })

    this._util.getObservableSnackInfo().subscribe(data=>{
      this.snack.open(data, "X",{
        panelClass: "info-snack",
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 3000
      })
    })
  }

  ngOnInit() {
    this.checkAndEnableServiceWorker()
  }

  checkAndEnableServiceWorker(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations.some((reg) => reg.active?.scriptURL.endsWith('upload-files-sw.js'))) {
          console.log('Custom Service Worker is already active.');
        } else {
          console.log('No active Custom Service Worker. Enabling it...');
          this.enableServiceWorker();
        }
      });
    } else {
      console.log('Service Worker is not supported in this browser.');
    }
  }

  enableServiceWorker(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/upload-files-sw.js')
        .then(() => console.log('Custom Service Worker registered successfully.'))
        .catch((error) => console.error('Service Worker registration failed:', error));
    }
  }

}
