import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {CognitoService} from "./modules/shared/services/cognito.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private _cognito: CognitoService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<unknown>, next: HttpHandler){
    let authenticatedRequest: HttpRequest<unknown> = request.clone();
    try {
      let token = (await this._cognito.getCurrentUser()).getAccessToken()
      if (!request.url.includes("jd-app-media")) authenticatedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.getJwtToken()}`,
        }
      });
    }catch (e) {
      console.log(e)
      authenticatedRequest = request.clone();
    }
    const handle = await next.handle(authenticatedRequest).toPromise()
    return handle!
  }
}
